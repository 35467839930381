<template>
  <base-layout title="Dashboard">
    <ion-row>
      <ion-col size="12" v-for="item in activeExams" :key="item.id">
        <ion-card class="flash">
          <ion-card-header>
            <ion-card-subtitle color="primary">
              {{ item.exam.name }}
            </ion-card-subtitle>
            <span v-if="allExamUsers.length > 0">
              <ion-card-subtitle
                v-if="checkExamUserState(item) !== true"
                color="danger"
              >
                Warning: Unpaid Dues!
              </ion-card-subtitle>
            </span>
            <ion-card-title> {{ item.subject.name }} </ion-card-title>
            <ion-card-subtitle
              >Date:
              {{
                moment(item.exam_schedule.start).format("ll")
              }}</ion-card-subtitle
            >
            <ion-card-subtitle
              >Time:
              {{
                moment(item.exam_schedule.start).format("LT") +
                " to " +
                moment(item.exam_schedule.end).format("LT")
              }}</ion-card-subtitle
            >
          </ion-card-header>
          <ion-card-content>
            <ion-button
              expand="full"
              fill="solid"
              color="success"
              @click="startOnlineExam(item)"
              >Start Now</ion-button
            >
          </ion-card-content>
        </ion-card>
      </ion-col>
      <ion-col size="12" class="ion-margin-bottom">
        <div>
          <canvas id="attendance-chart"></canvas>
        </div>
      </ion-col>
      <ion-col size="12" class="ion-margin-vertical">
        <div>
          <canvas id="invoice-chart"></canvas>
        </div>
      </ion-col>
      <ion-col size="12" class="ion-margin-vertical" v-if="chaptersInProgress">
        <span v-if="chaptersInProgress.length > 0">
          <ion-item lines="none">
            <ion-label>Chapters in progress</ion-label>
          </ion-item>
          <ion-card v-for="item in chaptersInProgress" :key="item.id">
            <ion-card-header>
              <ion-card-subtitle> {{ item.subject.name }} </ion-card-subtitle>
              <ion-card-title>{{ item.name }}</ion-card-title>
            </ion-card-header>
            <ion-card-content>
              {{ item.description }}
            </ion-card-content>
          </ion-card>
        </span>
        <span v-else>
          <ion-item lines="none">
            <ion-label>No chapters in progress</ion-label>
          </ion-item>
        </span>
      </ion-col>
    </ion-row>
  </base-layout>
</template>

<script>
import BaseLayout from "../../Base/BaseLayout";
import moment from "moment";

import {
  Chart,
  ArcElement,
  LineElement,
  BarElement,
  PointElement,
  BarController,
  BubbleController,
  DoughnutController,
  LineController,
  PieController,
  PolarAreaController,
  RadarController,
  ScatterController,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  RadialLinearScale,
  TimeScale,
  TimeSeriesScale,
  Decimation,
  Filler,
  Legend,
  Title,
  Tooltip,
  SubTitle,
} from "chart.js";

Chart.register(
  ArcElement,
  LineElement,
  BarElement,
  PointElement,
  BarController,
  BubbleController,
  DoughnutController,
  LineController,
  PieController,
  PolarAreaController,
  RadarController,
  ScatterController,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  RadialLinearScale,
  TimeScale,
  TimeSeriesScale,
  Decimation,
  Filler,
  Legend,
  Title,
  Tooltip,
  SubTitle
);

import {
  IonRow,
  IonCol,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardContent,
  IonCardSubtitle,
  IonItem,
  IonLabel,
  alertController,
  IonButton,
} from "@ionic/vue";

export default {
  components: {
    BaseLayout,
    IonRow,
    IonCol,
    IonCard,
    IonCardHeader,
    IonCardTitle,
    IonCardContent,
    IonCardSubtitle,
    IonItem,
    IonLabel,
    IonButton,
  },

  data() {
    return {
      attendanceChartData: null,
      invoiceChartData: null,
      moment,
    };
  },

  computed: {
    user() {
      return this.$store.getters["auth/user"];
    },

    attendancesForSession() {
      return this.$store.getters["attendance/attendanceForSession"];
    },

    allInvoices() {
      return this.$store.getters["invoice/allResources"];
    },

    chaptersInProgress() {
      return this.$store.getters["chapter/inProgress"];
    },

    activeExams() {
      return this.$store.getters["examSubject/active"];
    },

    allExamUsers() {
      return this.$store.getters["examUser/allResources"];
    },
  },

  methods: {
    alerter(header, message, buttons = ["close"]) {
      const alert = alertController.create({
        header: header,
        message: message,
        buttons: buttons,
      });

      return alert;
    },
    async loadAttendanceChart() {
      const user_id = this.user.id;
      await this.$store.dispatch("attendance/attendanceForSession", user_id);

      const loadedData = this.attendancesForSession;
      let labels = [];
      let present = [];
      let absent = [];

      for (let month in loadedData) {
        labels.push(month);
        const monthly_data = loadedData[month];
        let count_present = 0;
        let count_absent = 0;
        monthly_data.forEach((day) =>
          day.attendance_state_id === 2 ? count_present++ : count_absent++
        );
        present.push(count_present);
        absent.push(count_absent);
      }

      this.attendanceChartData = {
        data: {
          labels: labels,
          datasets: [
            {
              label: "Present",
              type: "bar",
              data: present,
              backgroundColor: "rgba(75, 192, 192, 0.8)",
              borderColor: "rgba(75, 192, 192)",

              borderWidth: 2,
            },

            {
              label: "Absent",
              type: "bar",
              data: absent,
              backgroundColor: "rgba(255, 99, 132, 0.8)",
              borderColor: "rgb(255, 99, 132)",
              borderWidth: 2,
            },
          ],
        },

        options: {
          responsive: true,
          lineTension: 1,
          scales: {
            x: {
              stacked: true,
              ticks: {
                display: function (context) {
                  return context.chart.width > 600;
                },
              },
            },
            y: {
              stacked: true,
            },
          },
          plugins: {
            title: {
              display: true,
              text: "Class Attendance",
            },
            legend: {
              position: "bottom",
            },
          },
        },
      };
    },

    async loadInvoiceChart() {
      const user_id = this.user.id;
      await this.$store.dispatch("invoice/allResources", user_id);

      const loadedData = this.allInvoices;
      let labels = ["Due", "Paid", "Pending"];
      let due = 0;
      let paid = 0;
      let pending = 0;

      loadedData.forEach((invoice) => {
        const amount = invoice.gross_amount_in_cent / 100;
        !invoice.payment ? (due += amount) : true;
        if (invoice.payment) {
          const status = invoice.payment.status;
          if (status === "created") {
            due += amount;
          }
          if (
            status === "verified" ||
            status === "authorised" ||
            status === "captured"
          ) {
            paid += amount;
          }
          if (status === "failed") {
            pending += amount;
          }
        }
      });

      this.invoiceChartData = {
        type: "doughnut",
        data: {
          labels: labels,
          datasets: [
            {
              label: "Invoice status",
              data: [due, paid, pending],
              backgroundColor: [
                "rgb(255, 205, 86)",
                "rgb(54, 162, 235)",
                "rgb(255, 99, 132)",
              ],
              hoverOffset: 4,
            },
          ],
        },

        options: {
          responsive: true,
          plugins: {
            title: {
              display: true,
              text: "Payment status",
            },
            legend: {
              position: "bottom",
            },
          },
        },
      };
    },

    async initAttendanceChart() {
      await this.loadAttendanceChart();
      const ctx = document.getElementById("attendance-chart");
      new Chart(ctx, this.attendanceChartData);
    },

    async initInvoiceChart() {
      await this.loadInvoiceChart();
      const ctx = document.getElementById("invoice-chart");
      new Chart(ctx, this.invoiceChartData);
    },

    async loadChaptersInProgress() {
      const user_id = this.user.id;
      await this.$store.dispatch("chapter/inProgress", user_id);
    },

    async loadActiveExams() {
      await this.$store.dispatch("examSubject/active");
    },

    async fetchAllExamUsers() {
      await this.$store.dispatch("examUser/allResources");
    },

    async checkExamUserState(item) {
      const exam_id = item.exam_id;
      const exam_user = await this.allExamUsers.find(
        (exam_user) => exam_user.exam_id === exam_id
      );

      return exam_user.exam_user_state.name == "Active" || exam_user.exam_user_state.name == "Provisional";
    },

    async startOnlineExam(item) {
      if ((await this.checkExamUserState(item)) == false) {
        this.offerPayment();
      } else {
        let exam_name = item.exam.name;
        let subject_name = item.subject.name;

        await this.$store.dispatch("examAnswer/createResource", {
          exam_subject_id: item.id,
        });

        this.$router.push({
          name: "exam.online",
          params: {
            exam_subject_id: item.id,
            exam_name,
            subject_name,
          },
        });
      }
    },

    async offerPayment() {
      const alertDialog = await this.alerter(
        "Unpaid Due!",
        "Please clear all unpaid dues or contact principal for permission to continue.",
        [
          {
            text: "Cancel",
            role: "cancel",
            cssClass: "secondary",
          },
          {
            text: "Pay Now",
            handler: () => {
              this.$router.push({
                name: "invoice",
              });
            },
          },
        ]
      );
      alertDialog.present();
    },
  },

  created() {
    this.initAttendanceChart();
    this.initInvoiceChart();
    this.loadChaptersInProgress();
    this.loadActiveExams();
    this.fetchAllExamUsers();
  },
};
</script>

<style scoped>
@keyframes flash {
  from {
    border: rgb(45, 211, 111) 2px solid;
  }
  to {
    border: rgb(255, 255, 255) 2px solid;
  }
}

.flash {
  border: rgb(45, 211, 111) 2px solid;
  animation-name: flash;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}
</style>
